import { Link } from "react-router-dom" 
import { useTranslation } from "react-i18next";


export const Navigation = () => {
  const { t } = useTranslation();


     return (
      <>
        <nav id="menu" aria-hidden="true" aria-labelledby="menu-toggle" role="navigation">
       
            <ul>
                <li><Link to="/" >{t("home")}</Link></li>
                <li><Link to="/contact" >Contact</Link></li>
                {/* <li><a href="https://www.noizybutpurple.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li> */}
      
            </ul>
           
     
            {/* <div id="logo">
                <picture>
                    <source type="image/webp" srcSet="../images/logo_noizybutpurple.webp" media="(min-width:340px)" />
                    <source type="image/png" srcSet="../images/logo_noizybutpurple.png" media="(min-width:340px)" />
                    <img src="../images/logo_noizybutpurple.png" alt="logo_noizybutpurple" /> 
                </picture>
                <p>
                www.noizybutpurple.com
                </p>
            </div> */}
        </nav>
      </>
    )
  }
  