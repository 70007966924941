import {useState, useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Header} from "../components/layout/Header";
import {Navigation} from "../components/layout/Navigation";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { sanitize } from 'dompurify';
// import '../assets/styles/story.scss';
import '../assets/styles/content.scss';
import { useTranslation } from "react-i18next";

export const Content = () => {
  const { t } = useTranslation();
  document.body.style.backgroundColor = "#070707";
  // document.body.style.backgroundColor = "#fff";
  let params = useParams();
  
  // const [story, setStory] = useState([]);
  // const [audio, setAudio] = useState([]);
  // const [nextAlien, setNextAlien] = useState([]);
  // const [shop, setShop] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(`/stories/${params.id}.json`)
  //     .then((res) => setStory(res.data))
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   if (story.audio !== 0 && typeof story.audio !== "undefined"){
  //       setAudio(<div className="audio-player"><audio controls><source src={`../audio/${story.alien_name}.mp3`} type="audio/mpeg" />Your browser does not support the audio element.</audio></div>); 
  //   };

  //   if (typeof story.next_alien !== "undefined" && story.next_alien !== ""){
  //       setNextAlien(<div id="next-alien">To continue reading the story line scan alien <span id="next-alien-name">{story.next_alien}</span></div>);
  //   };

  //   if (typeof story.shop !== "undefined" && story.shop !== 0){
  //       const storyStyle = {
  //           backgroundImage: `url(../images/InteractiveArtworks_${story.alien_name}.jpg)`,
  //           backgroundImage: `url(../images/InteractiveArtworks_${story.alien_name}.webp)`,
  //       }
  //       setShop(<div id="story-end" style={storyStyle}><a rel="noopener noreferrer" target="_blank" className="btn" id="shop-btn" href="https://shop.noizybutpurple.com/interactive-portraits" >Shop Artwork</a></div>);
  //   };
  //   }, [story]);

  function togglePlayButton(e){
    let audio_name = document.getElementById('player').src.split("/");
   

    if (e.currentTarget.id == 'pause-button'){
      e.currentTarget.id = 'play-button';
      document.getElementById('player').pause();
    }else{
      e.currentTarget.id = 'pause-button';
      document.getElementById('player').play();
    }
  }

  //get the initial Name of the first audio File
  useEffect(() => {
    let audio_name = document.getElementById('player').src.split("/")[4].split(".")[0];
    //document.getElementById('audio_name').innerHTML=audio_name;
    document.getElementById('audio_name').innerHTML="Intro";
  },[]);
 
  return (
    <>  
     <HelmetProvider>  
        <Helmet>
            <title>kometa app - {params.id}</title>
        </Helmet>
        <Header newClass="content"/>
        <Navigation />

        <div id="cometa-header">
                <div id="cometa-header-image">
                    <picture>
                        <source type="image/webp" srcSet={t("cometa_bg_img_webp")} media="(min-width:340px)" />
                        <source type="image/png" srcSet={t("cometa_bg_img_jpg")} media="(min-width:340px)" />
                        <img src={t("cometa_bg_img_jpg")} alt="Kometa_header_Img" />
                    </picture>
          </div>
        </div>

        <div id="cometa-text">
        <h2>{t("h2_cometa")}</h2>
        <h3>{t("h3_cometa")}</h3>
        <p>{t("cometa_text")}</p>
        </div>

        <audio id="player" src={t("audio_cometa")}></audio>



        <div id="audio-player"> 
          <div id="audio_name"></div>
          <div id="audio-buttons">
            <button className="audio-button" id="previous-song" ></button> 
            <button className="audio-button" id="play-button" onClick={togglePlayButton}></button> 
            <button className="audio-button" id="next-song" ></button>
          </div>    
          <div className="volume-slider">
          <div className="volume-percentage"></div>
      </div>



          


          {/* <button onclick="document.getElementById('player').volume += 0.1">Vol +</button> 
          <button onclick="document.getElementById('player').volume -= 0.1">Vol -</button>  */}
        </div>

        {/* <div id="story">
           <div id="story-header">
                <div id="story-header-image">
                    <picture>
                        <source type="image/webp" srcSet={`../images/${story.alien_name}_story_header.webp`} media="(min-width:340px)" />
                        <source type="image/png" srcSet={`../images/${story.alien_name}_story_header.jpg`} media="(min-width:340px)" />
                        <img src={`../images/${story.alien_name}_story_header.jpg`} alt={`of ${story.alien_name}`} />
                    </picture>
          </div>
            <div id="alien-meta">
              Meet {story.alien_name} - Alien {story.alien_number}
            </div>
            <div id="triangle"></div>
          </div>
          <div id="story-saying">
            <h1 dangerouslySetInnerHTML={{__html: sanitize(story.alien_saying)}}></h1>
          </div>
        <div id="story-section">  
            <div className="content">
                {audio}
                <div dangerouslySetInnerHTML={{__html: sanitize(story.alien_story)}} className="story-text">
                </div>
            </div>
        </div>
        {nextAlien}
        {shop}
        </div> */}
     </HelmetProvider>
    </>


  );
}
