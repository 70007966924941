// import Helmet from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Header} from "../components/layout/Header";
import {Navigation} from "../components/layout/Navigation";
import '../assets/styles/contact.scss';
import {ContactHeader} from "../components/layout/ContactHeader"



export const Contact = () => {
  document.body.style.backgroundColor = "#cb3376";
  return (
    <>  
     <HelmetProvider>  
        <Helmet>
            <title>noizybutpurple app - contact</title>|
        </Helmet>
        <Header />
        <Navigation />
        <main id="contact-main">
          <ContactHeader/>
          <div id="contact-content">
            <div id="contact-data">
                Marina Gröpel<br/>
                8057 Zürich<br/>
                Schweiz<br/><br/> 
            </div>
            <ul>
              <li><span>Telefonnummer:</span><a href="tel:+41795355425">+41 79 535 54 25</a></li>
              <li><span>Instagram:</span><a href="https://www.instagram.com/noizybutpurple/" target="_blank" rel="noopener noreferrer">@noizybutpurple</a></li>
              <li><span>Email:</span><a href="mailto:noizybutpurple@googlemail.com" target="_blank" rel="noopener noreferrer">noizybutpurple@googlemail.com</a></li>
              <li><span>Website:</span><a href="https://www.noizybutpurple.com/" target="_blank" rel="noopener noreferrer">www.noizybutpurple.com</a></li>            
            </ul>

          </div>
        </main>
     </HelmetProvider>
    </>


  );
}
