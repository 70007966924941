import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Header} from "../components/layout/Header";
import {Navigation} from "../components/layout/Navigation";
import {ContentHeader404} from "../components/layout/ContentHeader404";
import { Link } from "react-router-dom" 
import '../assets/styles/404.scss';

export const NotFoundPage = () => {
    return (
      <>  
      <HelmetProvider>  
         <Helmet>
             <title>noizybutpurple app - 404</title>
         </Helmet>
         <Header newClass="header-404"/>
         <Navigation />
         <main>
             <ContentHeader404/>
             <div className='content-404'>
             <Link to="/" ><div className="btn" id="btn-404">
                Back to Home
                </div></Link>
             </div>
       
         </main>
      </HelmetProvider>
     </>
    )
  }