import {NavButton} from "../buttons/NavButton";
import { LANGUAGES } from "../../assets/constants/index.js";
import { useTranslation } from "react-i18next";

export const Header = (props) => {

    const { i18n } = useTranslation();
    console.log(i18n.language);
    const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
      };

    return (
        <header className={`banner ${props.newClass ? props.newClass : ""}`} id="banner">
            <NavButton />
            <label>
                <select defaultValue={i18n.language} onChange={onChangeLang}>
                  {LANGUAGES.map(({ code, label }) => (
                    <option key={code} value={code}>
                      {label}
                    </option>
                    ))}
                </select>
              </label>
        </header>
    )
  }
  