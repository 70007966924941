export const ContactHeader = () => {
  return (
    <div id="contact-header">
        
    <div id="headlines-contact">
      <h1 className="h1-contact">&laquo;Contact Me&raquo;</h1>
     
    </div>
  </div>
  )
}
