import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend"

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
  .use(XHR) 
  .use(LanguageDetector)
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: "en",
    // lng: "en",
    interpolation: {
      escapeValue: false,
    },
    backend: {
        //loadPath: "http://192.168.0.12:3000/i18n/{{lng}}.json",
        // loadPath: "http://192.168.0.38:3000/i18n/{{lng}}.json",
        loadPath: "https://pretotyp.noizybutpurple.com/i18n/{{lng}}.json",
        // loadPath: "https://pretotyp.noizybutpurple.com/i18n/{{lng}}.json",
    },
  });

export default i18n;