export const ContentHeader404 = () => {
  document.body.style.backgroundColor = "rebeccapurple";
  return (
    <div id="content-header-404">
        	<div id="profile-picture-404">
    </div>
    <div id="headlines-404">
      <h1 className="h1-404">&laquo;404 - Page not found&raquo;</h1>
      <h2 className="h2-404">The page you're looking for might be renamed, removed, or might never exist in the first place.</h2>
    </div>
  </div>
  )
}
