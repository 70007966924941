import { useEffect } from "react";

import "../../assets/styles/cookieconsent.css";
import "../../assets/scripts/cookieconsent.js";

export default function CookieConsent() {
    useEffect(() => {
        const cc = window.initCookieConsent();

        cc.run({
            current_lang: 'en',
            autoclear_cookies: true,                   // default: false
            page_scripts: true,                        // default: false
        
            // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
            // delay: 0,                               // default: 0
            // auto_language: null                     // default: null; could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            // force_consent: false,                   // default: false
            // hide_from_bots: false,                  // default: false
            // remove_cookie_tables: false             // default: false
            // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
            // cookie_expiration: 182,                 // default: 182 (days)
            // cookie_necessary_only_expiration: 182   // default: disabled
            // cookie_domain: location.hostname,       // default: current domain
            cookie_domain: "noizybutpurple.com",       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'Lax',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            revision: 2,                                // default: 0
        
            onFirstAction: function(user_preferences, cookie){
                // callback triggered only once
            },
        
            onAccept: function (cookie) {
                // ...
            },
        
            onChange: function (cookie, changed_preferences) {
                // ...
            },
        
            languages: {
                'en': {
                    consent_modal: {
                        title: 'We use cookies!',
                        description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                        revision_message: '<br> Dude, my terms have changed. Sorry for bothering you again!',
                        primary_btn: {
                            text: 'Accept all',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Reject all',
                            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: 'Cookie preferences',
                        save_settings_btn: 'Save my choises ',
                        accept_all_btn: 'Accept all',
                        reject_all_btn: 'Reject all',
                        close_btn_label: 'Close',
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Expiration'},
                            {col4: 'Description'}
                        ],
                        blocks: [
                            {
                                title: 'Cookie usage 📢',
                                description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://www.noizybutpurple.com/privacy-policy" class="cc-link">privacy policy</a> (German only).'
                            }, {
                                title: 'Strictly necessary cookies',
                                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                },
                                cookie_table: [             // list of all expected cookies
                                {
                                    col1: 'cc_cookie',
                                    col2: 'noizybutpurple.com',
                                    col3: '182 days',
                                    col4: 'Set when a user completes cookie banner. Ensures the banner does not reappear if it has already been used.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                }
                                ]
                            }, {
                                title: 'Performance and Analytics cookies',
                                description: 'These cookies are used to collect information to analyze the traffic to our website and how visitors are using our website.<br><br> For example, these cookies may track things such as how long you spend on the website or the pages you visit which helps us to understand how we can improve our website site for you. <br><br>The information collected through these tracking and performance cookies do not identify any individual visitor.',
                                toggle: {
                                    value: 'analytics',     // your cookie category
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '^_ga',       // match all cookies starting with "_ga"
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Used to distinguish users',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_ga_<container-id>',
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Used to persist session state.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjSessionUser_<site_id>',
                                        col2: 'hotjar.com',
                                        col3: '1 year',
                                        col4: 'Set when a user first lands on a page. Persists the Hotjar User ID which is unique to that site.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjFirstSeen',
                                        col2: 'hotjar.com',
                                        col3: 'Session duration',
                                        col4: 'Identifies a new user’s first session. Used by Recording filters to identify new user sessions.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjAbsoluteSessionInProgress',
                                        col2: 'hotjar.com',
                                        col3: '30 minutes',
                                        col4: 'Used to detect the first pageview session of a user.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjSession_<site_id>',
                                        col2: 'hotjar.com',
                                        col3: '30 minutes',
                                        col4: 'Holds current session data. Ensures subsequent requests in the session window are attributed to the same session.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjSessionRejected',
                                        col2: 'hotjar.com',
                                        col3: 'Session duration',
                                        col4: 'If present, set to "1" for the duration of a user\'s session, when Hotjar has rejected the session from connecting to our WebSocket due to server overload. Applied in extremely rare situations to prevent severe performance issues.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjSessionResumed',
                                        col2: 'hotjar.com',
                                        col3: 'Session duration',
                                        col4: 'Set when a session/recording is reconnected to Hotjar servers after a break in connection.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjLocalStorageTest',
                                        col2: 'hotjar.com',
                                        col3: 'Under 100ms',
                                        col4: 'Checks if the Hotjar Tracking Code can use local storage.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjIncludedInPageviewSample',
                                        col2: 'hotjar.com',
                                        col3: '30 minutes',
                                        col4: 'Set to determine if a user is included in the data sampling defined by your site\'s pageview limit.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjIncludedInSessionSample',
                                        col2: 'hotjar.com',
                                        col3: '30 minutes',
                                        col4: 'Set to determine if a user is included in the data sampling defined by your site\'s daily session limit.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjTLDTest',
                                        col2: 'hotjar.com',
                                        col3: 'Session duration',
                                        col4: 'Enables Hotjar to try to determine the most generic cookie path to use, instead of page hostname.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjDonePolls',
                                        col2: 'hotjar.com',
                                        col3: '1 year',
                                        col4: 'Set when a user completes an on-site Survey. Ensures the same Survey does not reappear if it has already been filled in.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_hjMinimizedPolls',
                                        col2: 'hotjar.com',
                                        col3: '1 year',
                                        col4: 'Set when a user minimizes an on-site Survey. Ensures that the Survey stays minimized when the user navigates through your site.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_pk_id<user_id>',
                                        col2: 'noizybutpurple.com/Matomo self hosted',
                                        col3: '13 months',
                                        col4: 'Used to store a few details about the user such as the unique visitor ID.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    },
                                    {
                                        col1: '_pk_ses<session_id>',
                                        col2: 'noizybutpurple.com/Matomo self hosted',
                                        col3: '30 minutes',
                                        col4: 'Short lived cookie used to temporarily store data for the visit.',
                                        is_regex: true,
                                        domain: "noizybutpurple.com"
                                    }
                                ]
                            }, 
                            // {
                            //     title: 'Advertisement and Targeting cookies',
                            //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                            //     toggle: {
                            //         value: 'targeting',
                            //         enabled: false,
                            //         readonly: false
                            //     }
                            // }, 
                            {
                                title: 'More information',
                                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:noizybutpurple@googlemail.com">contact us</a>.',
                            }
                        ]
                    }
                },
                'de': {
                consent_modal: {
                    title: 'Wir verwenden Cookies!',
                    description: 'Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt werden. <button type="button" data-cc="c-settings" class="cc-link">Einstellungen ändern</button>',
                    revision_message: '<br> Meine Bedingungen haben sich geändert. Tut mir leid, dass ich dich wieder belästige!',
                    primary_btn: {
                        text: 'Alle akzeptieren',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Alle ablehnen',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Cookie Einstellungen',
                    save_settings_btn: 'Auswahl speichern',
                    accept_all_btn: 'Alle akzeptieren',
                    reject_all_btn: 'Alle ablehnen',
                    close_btn_label: 'Schliessen',
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Gültigkeitsdauer'},
                        {col4: 'Beschreibung'}
                    ],
                    blocks: [
                        {
                            title: 'Cookie Einsatz 📢',
                            description: 'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich aktiv ist. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="https://www.noizybutpurple.com/privacy-policy" class="cc-link">Datenschutzerklärung</a>.'
                        }, {
                            title: 'Notwendige Cookies',
                            description: 'Diese Cookies sind für das ordnungsgemässe Funktionieren dieser Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                            cookie_table: [             // list of all expected cookies
                            {
                                col1: 'cc_cookie',
                                col2: 'noizybutpurple.com',
                                col3: '182 Tage',
                                col4: 'Wird gesetzt, wenn ein Benutzer das Cookie-Banner bestätigt. Stellt sicher, dass das Banner nicht erneut angezeigt wird, wenn es bereits verwendet wurde.',
                            }
                            ]
                        }, {
                            title: 'Leistungs- und Analyse-Cookies',
                            description: 'Diese Cookies werden zur Sammlung von Informationen verwendet, um den Traffic auf unserer Website und die Nutzung unserer Website durch die Besucher zu analysieren. <br><br> Diese Cookies können z.B. aufzeichnen, wie lange Sie auf der Website verweilen oder welche Seiten Sie besuchen, was uns hilft zu verstehen, wie wir unsere Website für Sie verbessern können. <br><br>Die durch diese Leistungs- und Analyse-Cookies gesammelten Informationen lassen keine Rückschlüsse auf einzelne Besucher zu.',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: '^_ga',       // match all cookies starting with "_ga"
                                    col2: 'google.com',
                                    col3: '2 Jahre',
                                    col4: 'Dient zur Unterscheidung von Benutzern',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_ga_<container-id>',
                                    col2: 'google.com',
                                    col3: '2 Jahre',
                                    col4: 'Wird verwendet, um den Sitzungsstatus zu erhalten.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjSessionUser_<site_id>',
                                    col2: 'hotjar.com',
                                    col3: '1 Jahr',
                                    col4: 'Wird gesetzt, wenn ein Benutzer zum ersten Mal auf einer Seite landet. Beinhaltet die Hotjar-Benutzer-ID bei, die für diese Seite eindeutig ist.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjFirstSeen',
                                    col2: 'hotjar.com',
                                    col3: 'Sitzungsdauer',
                                    col4: 'Identifiziert die erste Sitzung eines neuen Benutzers. Wird von Aufzeichnungsfiltern verwendet, um neue Benutzersitzungen zu identifizieren.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjAbsoluteSessionInProgress',
                                    col2: 'hotjar.com',
                                    col3: '30 Minuten',
                                    col4: 'Wird verwendet, um den ersten Seitenaufruf eines Benutzers zu erkennen.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjSession_<site_id>',
                                    col2: 'hotjar.com',
                                    col3: '30 Minuten',
                                    col4: 'Enthält die aktuellen Sitzungsdaten. Stellt sicher, dass nachfolgende Anfragen im Sitzungsfenster der gleichen Sitzung zugeordnet werden.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjSessionRejected',
                                    col2: 'hotjar.com',
                                    col3: 'Sitzungsdauer',
                                    col4: 'Falls vorhanden - wird für die Dauer der Sitzung eines Benutzers auf "1" gesetzt, wenn Hotjar die Verbindung zu unserem Server aufgrund von Serverüberlastung abgelehnt hat. Wird in extrem seltenen Situationen angewendet, um schwerwiegende Leistungsprobleme zu vermeiden.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjSessionResumed',
                                    col2: 'hotjar.com',
                                    col3: 'Sitzungsdauer',
                                    col4: 'Wird festgelegt, wenn eine Sitzung/Aufnahme nach einer Unterbrechung der Verbindung erneut mit Hotjar-Servern verbunden wird.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjLocalStorageTest',
                                    col2: 'hotjar.com',
                                    col3: 'Unter 100ms',
                                    col4: 'Überprüft, ob der Hotjar Tracking Code lokalen Speicher verwenden kann.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjIncludedInPageviewSample',
                                    col2: 'hotjar.com',
                                    col3: '30 Minuten',
                                    col4: 'Legt fest, ob ein Benutzer in die Datenstichprobe einbezogen wird, die durch das Seitenaufruflimit Ihrer Website definiert ist.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjIncludedInSessionSample',
                                    col2: 'hotjar.com',
                                    col3: '30 Minuten',
                                    col4: 'Legt fest, ob ein Benutzer in die Datenstichprobe einbezogen wird, die durch das tägliche Sitzungslimit Ihrer Site definiert ist.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjTLDTest',
                                    col2: 'hotjar.com',
                                    col3: 'Sitzungsdauer',
                                    col4: 'Ermöglicht es Hotjar, anstelle des Hostnamens der Seite den allgemeinsten Cookie-Pfad zu ermitteln, der zu verwenden ist.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjDonePolls',
                                    col2: 'hotjar.com',
                                    col3: '1 Jahr',
                                    col4: 'Wird festgelegt, wenn ein Benutzer eine Umfrage abschliesst. Stellt sicher, dass die gleiche Umfrage nicht erneut angezeigt wird, wenn sie bereits ausgefüllt wurde.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_hjMinimizedPolls',
                                    col2: 'hotjar.com',
                                    col3: '1 Jahr',
                                    col4: 'Legt fest, wann ein Benutzer eine Umfrage minimiert. Stellt sicher, dass die Umfrage minimiert bleibt, wenn der Benutzer durch Ihre Website navigiert.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_pk_id<user_id>',
                                    col2: 'noizybutpurple.com/Matomo selbst gehostet',
                                    col3: '13 Monate',
                                    col4: 'Dient zur Speicherung einiger Details über den Benutzer, wie z. B. der eindeutigen Besucher-ID.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                },
                                {
                                    col1: '_pk_ses<session_id>',
                                    col2: 'noizybutpurple.com/Matomo selbst gehostet',
                                    col3: '30 Minuten',
                                    col4: 'Kurzlebiges Cookie, das zur vorübergehenden Speicherung von Daten für den Besuch verwendet wird.',
                                    is_regex: true,
                                    domain: "noizybutpurple.com"
                                }
                            ]
                        }, 
                        // {
                        //     title: 'Advertisement and Targeting cookies',
                        //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        //     toggle: {
                        //         value: 'targeting',
                        //         enabled: false,
                        //         readonly: false
                        //     }
                        // }, 
                        {
                            title: 'More information',
                            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:noizybutpurple@googlemail.com">contact us</a>.',
                        }
                    ]
                }
            }
        }
            
        });

    }, []);

    return null;
}