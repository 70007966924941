import { useTranslation } from "react-i18next";

export const ContentHeaderHome = () => {
  const { t } = useTranslation();
  return (
    <div id="content-header">
        	<div id="profile-picture">
    </div>
    <div id="headlines">
      <h1 className="home-h1">&laquo;{t("headline")}&raquo;</h1>
      <h2 className="home-h2">&ndash; Markthalle Burgdorf</h2>
    </div>
  </div>
  )
}
