import {useRef, useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
import { SyncLoader } from "react-spinners";
import ImageResize from "../ImageResizer";


const instance = axios.create();
instance.interceptors.request.use(AxiosLogger.requestLogger);

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export const CometaButton = (props) => {
  

  //is set with the prediction of the model
  const [predictions, setPredictions] = useState(null);

  //States for image resize
  const [imageToResize, setImageToResize] = useState(undefined);
  const [resizedImage, setResizedImage] = useState(null);
  //resizedImage is String, but we need an File Object - therefore we need these states
  const [resizedImageFile, setResizedImageFile] = useState(null);

  const dataLoaded = useRef(false);
  const navigate = useNavigate();
  //states for the spinner
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#fae03c");

  const [errorMessage, setErrorMessage] = useState('');

  function errorBtnHandler() {
    setErrorMessage('');
  }

  //css for the spinner
  const override: CSSProperties = {
    margin: "0 auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  };


  //open file input and set dataLoaded 
  function callInput(){

      document.getElementById('image-selector').click();
      dataLoaded.current = true;
  }

  //check if file is an image 
  function changeHandler(e){
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      setErrorMessage({headline: "Wrong File Type", error: "Unfortunately, we can only analyze png, jpg and jpeg files. Please try again or change your camera settings."});
      return;
    }
    setImageToResize(file);
    setLoading(!loading);
  };
  


// Convert the image file to an ArrayBuffer


  useEffect(() => {
    if(resizedImage){    
      let blob = dataURItoBlob(resizedImage);
      blob = blob.slice(0, blob.size, "image/jpeg")
      setResizedImageFile(blob);
    }

  },[resizedImage]);


  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], );
}


  //call prediction api after image was saved to server
  useEffect(() => {
    if(resizedImageFile != null){

      const response = axios({
        method: "post",
        url: "https://westeurope.api.cognitive.microsoft.com/customvision/v3.0/Prediction/589407ea-3a67-40de-bc52-f3e39aeba4ee/classify/iterations/Iteration9/image",
        data: resizedImageFile,
        headers: { "Content-Type": "application/octet-stream", "Prediction-Key": "d54bc8a835654c2583f634ed40aceae7" },
      }).then(function (response) {
        //handle success
        console.log(response);
  
        if(response.status == "200"){
          setPredictions(response.data.predictions)
        }else{
          setErrorMessage({headline: "Timeout", error: "We can't reach our AI servers at the moment. Please try again."});
          setLoading(!loading);
        }
       
      }).catch(function (response) {
        //handle error
        console.log(response);
        setErrorMessage({headline: "Timeout", error: "We can't reach our AI servers at the moment. Please try again."});
        setLoading(!loading);
      });  

    }
    
  },[resizedImageFile])

  
  //when predictions are set, get top probability and open alien story
  useEffect(() => {
    if(predictions != null){
      let top1 = Array.from(predictions)
      .map(function(p, i) { // this is Array.map
        return {
          probability: p.probability,
          tagName: p.tagName // we are selecting the value from the obj
        };
      }).sort(function(a, b) {
        return b.probability - a.probability;
      }).slice(0, 1);//only return highest prop
      console.log(top1[0].probability);
      if(top1[0].probability>0.5){
        setLoading(!loading);
        // setErrorMessage({headline: "Artwork recognized", error: "Great!"});
        // navigate('/content/'+top1[0].tagName);
        navigate('/content/'+top1[0].tagName);
      }else{
        //setErrorMessage({headline: "Artwork not recognized", error: "Please try to take a picture of the whole artwork from the front as close as possible."});
        setLoading(!loading);
        navigate('/content/'+top1[0].tagName);
      }
    }
  }, [predictions]);


  return (
    <>
      <div className="btn" id={props.id} onClick={callInput}>{props.txt}</div>
      <input type="file"  accept="image/*" capture="camera" name="imageToUpload" id="image-selector" onChange={changeHandler}/>
      <ol id="prediction-list"></ol>
      <SyncLoader className="spinner" color={color} loading={loading} cssOverride={override} size={20} margin={4}/>
      <ImageResize imageToResize={imageToResize} onImageResized={(resizedImage) => setResizedImage(resizedImage)}/>
      {errorMessage && (
        <div id="overlay">
        <div id="error-message">
          <h3>{errorMessage.headline}</h3>
          <p>{errorMessage.error}</p>
          <div className="btn" id="error-btn" onClick={errorBtnHandler}>Ok</div>
        </div>
       </div>
      )}
      
      {
                resizedImage &&
                
                    <img id="resized-image"
                        alt="Resize Image"
                        src={resizedImage}
                    />

            }
       
    </>
  )
}
