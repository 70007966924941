import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Header} from "../components/layout/Header";
import {Navigation} from "../components/layout/Navigation";
import {ContentHeaderHome} from "../components/layout/ContentHeaderHome";
import '../assets/styles/home.scss';
import { CometaButton } from '../components/buttons/CometaButton';
import PWAPrompt from 'react-ios-pwa-prompt'
import { useTranslation } from "react-i18next";

export const Home = () => {
  document.body.style.backgroundColor = "#008bb7";
  const { t } = useTranslation();
  return (
    <>  
     <HelmetProvider>  
        <Helmet>
            <title>pretotyp cometa</title>
        </Helmet>
        <Header/>
        <Navigation />
        <main>
            <ContentHeaderHome/>
            <div id="btns-home">
                {/* <ScanButton txt="Scan Artwork" id="scan-btn"/> */}
                <CometaButton txt={t("button")} id="scan-btn"/>
                {/* <ScanButtonOffline txt="Scan Artwork" id="scan-btn"/> */}
            </div>
            {/* <PWAPrompt promptOnVisit={1} timesToShow={5} copyClosePrompt="Close" permanentlyHideOnDismiss={false}/> */}
        </main>
     </HelmetProvider>
    </>


  );
}
